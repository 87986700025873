<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title style="background-color: #7253cf; color: white">
          <div
            v-if="layout === 'AdminDashboardLayout'"
            class="d-flex px-1 ml-2"
          >
            <img
              style="margin-top: -15px; margin-bottom: 15px"
              src="@/assets/sponsorModalLogo.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-5" v-if="layout === 'CampaignDashboardLayout'">
            <img
              src="@/assets/DistrictCampaign/sponsorManagementModalLogo.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-7 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleAddEditSponsorModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form lazy-validation ref="sponsorForm">
            <v-row no-gutters style="margin-top: 20px">
              <v-col v-if="previewImage" cols="6" class="px-0">
                <img :src="previewImage" alt="" size="100%" class="logo" />
              </v-col>
              <v-col class="file-input-col pt-5" cols="5">
                <div v-if="type == 'add' && !previewImage">
                  <v-btn
                    text
                    dark
                    color="#38227A"
                    class="text-capitalize px-5"
                    style="border: 1px solid #38227a; border-radius: 8px"
                    @click="$refs.inputFile.click()"
                    ><span>upload Logo</span></v-btn
                  >
                </div>
                <div v-if="type == 'edit' || previewImage" style="float: right">
                  <v-btn
                    text
                    dark
                    color="#38227A"
                    class="text-capitalize px-5"
                    style="border: 1px solid #38227a; border-radius: 10px"
                    @click="$refs.inputFile.click()"
                    ><span>change logo</span></v-btn
                  >
                </div>
                <label
                  for="inputId"
                  ref="inputFile"
                  style="display: none"
                ></label>
                <v-file-input
                  accept="image/png,image/jpeg,image/svg+xml"
                  label="Upload Logo"
                  prepend-icon
                  outlined
                  color="#7253CF"
                  dense
                  class="file-input"
                  id="inputId"
                  @change="onUploadLogoChange"
                  style="display: none"
                  ref="InputFile"
                >
                </v-file-input>
              </v-col>
              <p v-if="!previewImage && type == 'add'" class="logo-des">
                Please upload a image for the sponsor logo,file size should be
                less than 10 MB
              </p>
            </v-row>
            <v-row class="mt-3" no-gutters justify="center">
              <v-col cols="11">
                <v-text-field
                  label="Sponsor Name"
                  outlined
                  dense
                  v-model="sponsorName"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="11">
                <v-text-field
                  label="Website"
                  outlined
                  dense
                  v-model="website"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="11">
                <v-text-field
                  label="Contact Person Name"
                  outlined
                  dense
                  v-model="contactPersonName"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="11">
                <v-text-field
                  label="Contact Person Email"
                  outlined
                  dense
                  v-model="contactPersonEmail"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="11">
                <v-text-field
                  label="Contact Person Phone"
                  outlined
                  dense
                  v-model="contactPersonPhone"
                  type="number"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="11">
                <v-text-field
                  label="Message From Sponsor"
                  outlined
                  dense
                  v-model="specialMessage"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-5 px-10">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText py-5 px-7 mt-5"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Add Sponsor</span>
          </v-btn>
          <v-btn
            :loading="formLoading"
            v-if="type == 'edit'"
            class="rounded-lg dialogAction-btnText py-5 px-7 mt-5"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
import {
  API_ADMIN_SPONSOR_MANAGEMENT_POST,
  API_ADMIN_SPONSOR_MANAGEMENT_UPDATE,
  API_ADMIN_SPONSOR_MANAGEMENT_GET,
} from "@/constants/APIUrls";
import authToken from "@/common/authToken";
import Axios from "@/api/BaseAxios";
export default {
  name: "AddEditSponsor",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      previewImage: null,
      LogoImgFile: {},
      sponsorName: "",
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonPhone: null,
      website: "",
      specialMessage: "",
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "sponsorManagement/getShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userType() {
      return authToken.decodedToken().user_type;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    layout() {
      return this.$route.meta.layout;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Sponsor Detail Added";
      } else {
        return "Sponsor Detail Updated";
      }
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditSponsorModal({ show: value });
      },
    },
    type() {
      return this.$store.state.sponsorManagement.addEditViewModal.type;
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Sponsor";
        case "edit":
          return "Edit Sponsor";
        default:
          return "";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleAddEditSponsorModal: "sponsorManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("in open call");
      if (this.type === "edit") {
        this.getSponsorDetail();
      }
    },
    closeCallBackModal() {
      this.loading = false;
      this.formLoading = false;
      this.previewImage = null;
      this.LogoImgFile = {};
      this.sponsorName = "";
      this.contactPersonName = "";
      this.contactPersonEmail = "";
      this.contactPersonPhone = null;
      this.website = "";
      this.specialMessage = "";
      console.log("closed");
      this.$refs.sponsorForm.reset();
    },
    onUploadLogoChange(payload) {
      // const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file) {
        if (file.size < 1000000) {
          this.LogoImgFile = payload;
          if (file) {
            this.previewImage = URL.createObjectURL(file);
            URL.revokeObjectURL(file); // free memory
          } else {
            this.previewImage = null;
          }
          console.log(this.previewImage, "previewImage");
          console.log(this.LogoImgFile, "LogoImgFile");
        } else {
          console.log("image validation error");
          this.showToast({
            message: "Logo file size must be less than 10 MB.",
            color: "e",
          });
        }
      }
    },
    getSponsorDetail() {
      const self = this;
      self.loading = true;
      const successHandler = (res) => {
        const data = res.data;
        console.log(data);
        this.sponsorName = data.sponsor_detail.sponsor_name;
        this.contactPersonName = data.sponsor_detail.contact_person_name;
        this.contactPersonEmail = data.sponsor_detail.contact_person_email;
        this.contactPersonPhone = data.sponsor_detail.contact_Person_phone;
        this.previewImage = data.sponsor_detail.sponsor_logo_url;
        this.website = data.sponsor_detail.website;
        this.specialMessage = data.sponsor_detail.sponsor_special_message;
        self.loading = false;
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
        self.loading = false;
      };
      let formData = {};
      formData["sponsor_id"] =
        this.$store.state.sponsorManagement.addEditViewModal.sponsorID;

      Axios.request_GET(
        API_ADMIN_SPONSOR_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      if (this.$refs.sponsorForm.validate()) {
        const self = this;
        self.formLoading = true;

        const successHandler = (res) => {
          const data = res.data;
          console.log(data);
          self.formLoading = false;
          this.toggleAddEditSponsorModal({ show: false });
          this.$emit("reload");
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
        };
        const failureHandler = (res) => {
          const data = res.data;
          console.log(data);
          self.formLoading = false;
          this.showToast({
            message: res,
            color: "e",
          });
        };

        let formData = new FormData();
        formData.append("sponsor_name", this.sponsorName);
        formData.append("contact_person_name", this.contactPersonName);
        formData.append("contact_person_email", this.contactPersonEmail);
        if (this.contactPersonPhone) {
          formData.append("contact_Person_phone", this.contactPersonPhone);
        }
        formData.append("website", this.website);
        formData.append("sponsor_special_message", this.specialMessage);
        if (this.type === "add") {
          formData.append("status", true);
        }
        if (this.LogoImgFile instanceof File) {
          formData.append("sponsor_logo", this.LogoImgFile);
        }
        if (this.type === "edit") {
          formData.append(
            "sponsor_id",
            this.$store.state.sponsorManagement.addEditViewModal.sponsorID
          );
        }
        if (this.layout === "CampaignDashboardLayout") {
          formData.append(
            "campaign",
            this.selectedCampaignData.campaignData.id
          );
          if (this.userTeam === "admin") {
            if (this.$route.matched[0].path.substring(1) === "district") {
              formData.append(
                "district_id",
                this.selectedCampaignData.organizationData.id
              );
            } else {
              formData.append(
                "school_id",
                this.selectedCampaignData.organizationData.id
              );
            }
          } else if (this.userTeam === "district") {
            if (this.$route.matched[0].path.substring(1) === "school") {
              formData.append(
                "school_id",
                this.selectedCampaignData.organizationData.id
              );
            }
          }
        }
        console.log(formData);
        if (this.previewImage) {
          if (this.type === "add") {
            Axios.request_POST(
              API_ADMIN_SPONSOR_MANAGEMENT_POST,
              formData,
              {},
              successHandler,
              failureHandler,
              false
            );
          } else {
            Axios.request_PATCH(
              API_ADMIN_SPONSOR_MANAGEMENT_UPDATE,
              formData,
              {},
              successHandler,
              failureHandler,
              false
            );
          }
        } else {
          this.showToast({
            message: "You cannot perform this action without a logo.",
            color: "w",
          });
          this.formLoading = false;
        }
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 20px;
}
img.logo {
  margin-left: 20px;
  max-height: 80px;
}
.file-input-col {
  margin-left: 20px;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
